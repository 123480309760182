$color-primary: #ad0028;
$color-white: #fff;
$color-purple: #ad0028;
$color-welcome-gray: #f3f5fd;
$color-transparent: transparent;
$thead-backgrond: #f5f5ff;
$brand-color: #1496A6;
$border-color: #d0d0d0;
$dark-color: #3a3a3c;
$header-background-color: #0d8d7014;
$header-background-color-2: #e0e8e6;
$row-background-color: #e9f0ff;
$red-color: #eb5757;
$label-color: #333333;
$span-dark-color: #000000;
$box-shadow-color: #0000000d;
$gray-1: #6e6e6e;
$icon-gray: #a9a9a9;
$gray-2: #e9edf5;
$secondary-brand-color: #0d8d701f;
$gray-3: #656565;
$secondary-color: #F25891;
$gray-4: #717171;
$secondary-color-2: #FEB2CE;

.secondary-color-2 {
  color: $secondary-color-2 !important;
}

.secondary-color {
  color: $secondary-color !important;
}

.gray4 {
  color: $gray-4 !important;
}

.color-banner-3 {
  color: $gray-3 !important;
}

.color-banner {
  color: #4c4c4c;
}
.chat-doc {
  color: #474747 !important;
}

.primary {
  color: $brand-color;
}
.icon-color {
  color: $gray-1 !important;
}

.delete {
  color: $red-color !important;
}

.white {
  color: $color-white;
}
.heading {
  color: $dark-color !important;
}

.span-color {
  color: $span-dark-color !important;
}

.bg-primary {
  background-color: $border-color !important;
}

.bg-purple {
  background-color: $color-purple;
}

.table-bg-color {
  background-color: $header-background-color !important;
}
.table-bg-color-2 {
  background-color: $header-background-color-2 !important;
}

.border-primary {
  border: 1px solid $border-color !important;
}

.bg-white {
  background-color: $color-white !important;
}

.bg-welcome-gray {
  background-color: $color-welcome-gray;
}

.bg-transparent {
  background-color: $color-transparent;
}

.border-none {
  border: none !important;
}

.thead-background {
  background-color: $thead-backgrond;
}

.t-border {
  border: 1px solid var(--Chat-outline, #d0d0d0) !important;
}

.font-family-roboto {
  font-family: "Roboto", sans-serif;
}

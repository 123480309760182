body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF !important;
  color: "black";
  font-family: "Urbanist", sans-serif !important;
}

code {
  font-family: "Urbanist", sans-serif !important;
}

//paddings

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 1px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-4 {
  padding: 4% !important;
}
.pt-3 {
  padding-top: 3px !important;
}
.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-6 {
  padding-left: 6px !important;
}
.pl-10 {
  padding-left: 10px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.px-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
//margins

.m {
  margin: 0px !important;
}
.m-auto {
  margin: 25px auto !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-2-neg {
  margin-top: -2px !important;
}
.mt-1 {
  margin-top: 1px !important;
}

.mt-3 {
  margin-top: 3px !important;
}
.mt-5 {
  margin-top: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}
//line height

.lh_1 {
  line-height: 1 !important;
}

.lh_2 {
  line-height: 2 !important;
}

.lh_10 {
  line-height: 10 !important;
}

.lh-18 {
  line-height: 18.75px !important;
}

.lh-19 {
  line-height: 19px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.lh-21 {
  line-height: 21.09px;
}

.lh_35 {
  line-height: 35px !important;
}

.lh_25 {
  line-height: 25.78px !important;
}

.lh_40 {
  line-height: 40px !important;
}

//border-radius
.br-2 {
  border-radius: 2px !important;
}

.br-6 {
  border-radius: 6px !important;
}

.br-15 {
  border-radius: 15px !important;
}

// border
.no-border {
  border: 0 !important;
}

.vh-100 {
  height: 100vh !important;
}

.h-100 {
  height: 100px !important;
}

.h-60 {
  height: 60px !important;
}
.h-230 {
  height: 230px !important;
}
.h-250 {
  height: 250px !important;
}

// widths
.w-95p {
  width: 95% !important;
}

.w-100p {
  width: 100% !important;
}

.h-100p {
  height: 100% !important;
}
.cursor {
  cursor: pointer !important;
}
.text-transform {
  text-transform: capitalize !important;
}
.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.markdown-no-margin > * {
  margin: 0 !important;
  width: 100% !important;
}

.markdown-container {
  max-width: 100%;
  overflow-x: auto;
}

/* Custom Scrollbar */
.markdown-container::-webkit-scrollbar {
  height: 5px; /* Set scrollbar height for horizontal scrolling */
}

.markdown-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 2px; /* Rounded edges */
}

.markdown-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
.scroll-target {
  scroll-margin-top: 100px;
}


@use "../../styles//scss/colors.scss" as *;

.nofFoundImg {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.homeBtn {
  width: fit-content;
  height: 40px;
  background: $brand-color !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  letter-spacing: 0px;
  border-radius: 50px !important;
  &:hover {
    background: $brand-color !important;
  }
}

//font sizes
.fs-10 {
  font-size: 0.625rem !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-13 {
  font-size: 0.8125rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}
.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-19 {
  font-size: 1.175rem !important;
}

.fs-20 {
  font-size: 1.25rem !important;
}

.fs-22 {
  font-size: 1.375rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-26 {
  font-size: 1.625rem !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-28 {
  font-size: 1.75rem !important;
}

.fs-45 {
  font-size: 2.8125rem !important;
}

.fs-65 {
  font-size: 65px !important;
}

.fs-120 {
  font-size: 120px !important;
}

//font weights
.fw-100 {
  font-weight: 100 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

// fonts
.pacifico {
  font-family: "Pacifico", cursive !important;
}

.roboto {
  font-family: "Urbanist", sans-serif !important;
}

.thead-fontSize {
  font-size: 16px !important;
  line-height: 26px !important;
}

.tdata-fontSize {
  font-size: 14px !important;
  line-height: 26px !important;
}

.thead-font-weight {
  font-weight: 500 !important;
}

.tdata-font-weight {
  font-weight: 400 !important;
}
